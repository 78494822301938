export default function PhotoIcon() {
  return (
    <svg
      viewBox="0 0 53 53"
      height={53}
      width={53}
      preserveAspectRatio="xMidYMid meet"
      className=""
      x="0px"
      y="0px"
      enableBackground="new 0 0 53 53"
    >
      <g>
        <defs>
          <circle id="image-SVGID_1_" cx={26.5} cy={26.5} r={25.5} />
        </defs>
        <clipPath id="image-SVGID_2_" />
        <g clipPath="url(#image-SVGID_2_)">
          <path
            fill="#AC44CF"
            d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"
          />
          <path
            fill="#BF59CF"
            d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"
          />
          <rect x={17} y={24.5} fill="#AC44CF" width={18} height={9} />
        </g>
      </g>
      <g fill="#F5F5F5">
        <path
          id="svg-image"
          d="M18.318 18.25 34.682 18.25C35.545 18.25 36.409 19.077 36.493 19.946L36.5 20.083 36.5 32.917C36.5 33.788 35.68 34.658 34.818 34.743L34.682 34.75 18.318 34.75C17.368 34.75 16.582 34.005 16.506 33.066L16.5 32.917 16.5 20.083C16.5 19.213 17.32 18.342 18.182 18.257L18.318 18.25 34.682 18.25ZM23.399 26.47 19.618 31.514C19.349 31.869 19.566 32.25 20.008 32.25L32.963 32.25C33.405 32.239 33.664 31.848 33.384 31.492L30.702 28.043C30.486 27.774 30.077 27.763 29.861 28.032L27.599 30.759 24.26 26.459C24.045 26.179 23.614 26.179 23.399 26.47ZM31.75 21.25C30.784 21.25 30 22.034 30 23 30 23.966 30.784 24.75 31.75 24.75 32.716 24.75 33.5 23.966 33.5 23 33.5 22.034 32.716 21.25 31.75 21.25Z"
        />
      </g>
    </svg>
  );
}
